<template>
  <div class="view-root" style="padding: 5%;box-sizing: border-box;">
    <p class="note-title" style="margin-bottom:30px ">My Refffs</p>
    <div class="note-list">
      <div v-for="(card,index) in dataList" :key="index" class="notes-item"
           @click="gotoDetail(card)">

        <p class="item-title">{{ card.decryptTitle }}</p>
        <div style="display: flex;flex-direction: row;justify-content: flex-start;flex-wrap: wrap">
          <div v-for="(label,index ) in card.labels" :key="index" class="label-item">
            {{ label }}
          </div>
        </div>
        <p style="width: 100%;text-align: right;color: #999999;font-size: 10px;margin-top: 8px">
          Created on {{ card.show_time }}
        </p>
      </div>
    </div>
    <div class="empty-view"></div>
  </div>
</template>

<script>
import {getMyNotes} from '../../../api/reff'
import {getUserId} from "../../../utils/store";
import '../../../assets/common/common.css'
import '../../../assets/common/font.css'
import {decryptValue} from "../../../utils/common";

export default {
  name: "index",
  data() {
    return {
      dataList: []
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    gotoDetail(card) {
      this.$router.push({
        path: '/mobile/home/view-refff',
        query: {
          id: card._id.$id,
        }
      })
    },
    fetchData() {
      this.dataList = []
      getMyNotes(getUserId()).then((res) => {
        let result = res.data.data
        for (let i = 0; i < result.length; i++) {
          let content = result[i]
          let des_content_new = content.des_content_new
          let decryptContent = this.doDecryptValue(des_content_new)
          let des_title_new = content.des_title_new
          let decryptTitle = this.doDecryptValue(des_title_new)

          let labels = []
          for (let i = 0; i < content.des_labels_new.length; i++) {
            let label = this.doDecryptValue(content.des_labels_new[i])
            labels.push(label)
          }
          content['labels'] = labels
          content['decryptContent'] = decryptContent
          content['decryptTitle'] = decryptTitle
          content['show_time'] = this.formatCreateTime(content.create_time)
          this.dataList.push(content)
        }
      })
    },
    formatCreateTime(create_time) {
      var date = new Date(create_time * 1000)
      let year = date.getFullYear()
      let day = date.getDate()
      let month = (date.getMonth() + 1)
      return this.formatMonthToEng(month) + ' ' + day + ', ' + year
    },
    doDecryptValue(encrypted) {
      return decryptValue(encrypted)
    },
    formatMonthToEng(month) {
      switch (month) {
        case 1:
          return 'Jan'
        case 2:
          return 'Feb'
        case 3:
          return 'Mar'
        case 4:
          return 'Apr'
        case 5:
          return 'May'
        case 6:
          return 'Jun'
        case 7:
          return 'Jul'
        case 8:
          return 'Aug'
        case 9:
          return 'Sep'
        case 10:
          return 'Oct'
        case 11:
          return 'Nov'
        case 12:
          return 'Dec'
      }
    },
  }
}
</script>

<style scoped lang="less">
.note-title {
  font-size: 22px;
  font-family: 'Lato';
  font-weight: normal;
  color: #333333;
}

.note-list {
  //display: grid;
  //align-items: flex-start;
  //grid-template-columns: 1fr 1fr;
  //column-gap: 15px;
  //row-gap: 15px;
}


.label-item {
  margin-bottom: 8px;
  margin-right: 5px;
  padding: 3px 5px;
  color: #1A51CD;
  font-size: 7px;
  border-radius: 12px;
  border-style: solid;
  border-color: #1A51CD;
  border-width: 1px;
  font-family: 'Lato';
}


.notes-item {
  width: 100%;
  cursor: pointer;
  margin-bottom: 15px;
  padding: 15px 20px;
  box-sizing: border-box;
  box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.08);

  .item-title {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-family: 'Lato';
    font-weight: normal;
    color: #333333;
  }

  .item-cover {
    object-fit: cover;
    width: 100%;
    height: 204px;
    border-radius: 6px;
    box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.08);
  }
}

</style>
